import React, { useState } from "react";
import { getBgColor, getColor } from "../utils/classPicker";
import { ContactForm } from "./ContactForm";
import { RichText } from "./RichText";
import logoIcon from "../images/instapay-logo-icon.png";

export const Contact = ({ columnLeft, columnRight }) => {
  const [message, setMessage] = useState(false);

  return (
    <section className="gradient-tTb-blue" id="contact">
      <div className="bg-lg-split py-0 py-lg-5rem">
        <div className="container-fluid container-lg">
          <div className="row">
            <div className="col-12 col-xl-11 mx-auto">
              <div className="row">
                <div className={`col-12 col-lg-6 d-flex py-5rem py-lg-0`}>
                  <div className="d-flex flex-wrap pr-lg-5rem">
                    <h2 className={`fs-2 font-weight-bold pb-4 ${getColor(1)}`}>
                      {columnLeft.title}
                    </h2>
                    <RichText
                      className={`pb-2rem ${getColor(1)}`}
                      text={columnLeft.description.raw}
                    />
                    {message ? (
                      <RichText
                        className={`pb-2rem font-weight-bold ${getColor(5)}`}
                        text={columnLeft.successMessage.raw}
                      />
                    ) : (
                      <ContactForm setMessage={setMessage} />
                    )}
                  </div>
                </div>
                <div
                  className={`col-12 col-lg-6 d-flex pl-lg-5rem py-5rem py-lg-0 ${getBgColor(
                    9
                  )}`}
                >
                  <div className="d-flex flex-wrap flex-column">
                    <div className="pb-2rem">
                      <img src={logoIcon} alt="Instapay Logo Icon" />
                    </div>
                    <h2 className="fs-2_25 font-weight-bold pb-4">
                      {columnRight.title}
                    </h2>
                    <RichText
                      className="fs-1_5"
                      text={columnRight.description.raw}
                    />
                    {columnRight.phone && (
                      <a
                        href={`tel:${columnRight.phone}`}
                        className="fs-2_75 font-weight-bold pb-4 c-black"
                      >
                        {columnRight.phone}
                      </a>
                    )}
                    <RichText
                      className="fs-1_5"
                      text={columnRight.description2.raw}
                    />
                    <a
                      href={`mailto:${columnRight.email}`}
                      className="fs-1_75 fs-sm-3 font-weight-bold pb-4 c-black"
                    >
                      {columnRight.email}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
