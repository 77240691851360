import { useEffect } from "react";

export const useOutsideAlerter = ({
  ref,
  isClicked,
  onClickOutside,
  exceptRefs = [],
}) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = (event) => {
      const isExceptedClicked =
        exceptRefs.filter((exceptRef) =>
          exceptRef.current.contains(event.target)
        ).length > 0;

      if (
        isClicked &&
        ref.current &&
        !ref.current.contains(event.target) &&
        !isExceptedClicked
      ) {
        // alert("You clicked outside of me!");
        onClickOutside();
      }
    };
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, isClicked]);
};
