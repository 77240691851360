import React, { useEffect, useState } from "react";
import { useScroll } from "../hooks/useScroll";
import { useScreenClick } from "../hooks/useScreenClick";
import { getPathname } from "../utils/location";
import { Link } from "gatsby";
import { LinkButton } from "./LinkButton";
import blueLogo from "../images/instapay-logo-blue.png";
import MenuBurgerIcon from "./MenuBurgerIcon";
import logoImg from "../images/instapay-logo-white.png";
import euFlag from "../images/eu-logo.png";
import { ContactEmail, Copyrights, SocialItems } from "./Common";
import { Dropdown } from "./Navbar/Dropdown";
import { PlainLink } from "./Navbar/PlainLink";
import { Matrix } from "./Navbar/Matrix";

export const Navbar = ({
  logo,
  navigationItems,
  backgroundColor = "",
  companyInformation,
}) => {
  const scroll = useScroll();
  const screenClick = useScreenClick();
  const [overlayRef, setOverlayRef] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [navbarClass, setNavbarClass] = useState(backgroundColor || "");
  const [logoImage, setLogoImage] = useState(logo.fluid.src);
  const { copyrights, contactEmail, socialMedia } = companyInformation;

  useEffect(() => {
    if (scroll.scrollY > 0) {
      setNavbarClass("shadow-sm bgc-white");
      setLogoImage(blueLogo);

      return;
    }

    if (window.innerWidth > 1024) {
      setNavbarClass(backgroundColor);
      setLogoImage(logo.fluid.src);

      return;
    }

    setNavbarClass("shadow-sm bgc-white");
    setLogoImage(blueLogo);
  }, [scroll]);

  useEffect(() => {
    if (!isOpen || window.innerWidth > 1024 || !overlayRef) {
      return;
    }

    const isClickInsideOverlay = overlayRef.contains(screenClick.target);

    if (!isClickInsideOverlay) {
      return;
    }

    setIsOpen(false);
  }, [screenClick]);

  return (
    <>
      <div
        className={`overlay ${isOpen && "overlay--open"}`}
        ref={(el) => setOverlayRef(el)}
      />
      <div className="h-4rem h-lg-6rem" />
      <nav
        className={`h-4rem h-lg-6rem navbar position-fixed d-flex et-0 justify-content-between align-items-center w-100 py-lg-6 ${navbarClass}`}
        style={{ zIndex: 99 }}
      >
        <div className="container-fluid container-xl">
          <div className="row">
            <div className="col-12 d-flex d-flex flex-wrap flex-md-nowrap justify-content-between align-items-center">
              <div className="d-flex align-items-center">
              <Link to="/">
                <img src={logoImage} alt="Instapay Logo" />
              </Link>

              <img src={euFlag} width={200} alt="Flaga EU" />
              </div>

              <div
                className={`flex-wrap d-flex align-content-between sidebar mb-0 ${
                  isOpen && "sidebar--open"
                }`}
              >
                <div className="d-block w-100">
                  <MenuBurgerIcon
                    onClick={setIsOpen}
                    isOpen={true}
                    colorClass="bgc-white bc-white"
                  />
                  <MenuItems
                    navigationItems={navigationItems}
                    setIsOpenSidebar={setIsOpen}
                  />
                </div>
                <div className="d-block d-lg-none w-100">
                  <div className="text-center pb-2">
                    <Link to="/">
                      <img
                        src={logoImg}
                        alt="Instapay Logo"
                        className="w-7rem w-lg-14rem pb-0 pb-lg-6"
                      />
                    </Link>
                  </div>
                  <ContactEmail contactEmail={contactEmail} />
                  <SocialItems items={socialMedia} />
                  <Copyrights
                    copyrights={copyrights}
                    className="d-block d-lg-none text-center"
                  />
                </div>
              </div>
              {!isOpen && (
                <MenuBurgerIcon onClick={setIsOpen} isOpen={isOpen} />
              )}
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

const MenuItems = ({ navigationItems, setIsOpenSidebar }) => (
  <ul
    className={`mb-0 ml-0 d-block d-lg-flex align-items-center pt-2rem pt-lg-0 pl-0 pr-2rem pr-lg-0`}
  >
    {navigationItems && navigationItems.items.map(
      ({
        id,
        url,
        label,
        dropdownItems,
        type = "plain-link",
        columnNumber = 3,
      }) => {
        if (url.startsWith("#")) {
          url = `${getPathname()}${url}`;
        }

        switch (type) {
          case "dropdown-list":
            return (
              <Dropdown label={label} dropdownItems={dropdownItems} key={id} />
            );
          case "matrix":
            return (
              <Matrix
                label={label}
                dropdownItems={dropdownItems}
                key={id}
                columnNumber={columnNumber}
              />
            );
          default:
            return (
              <PlainLink
                key={id}
                label={label}
                url={url}
                setIsOpenSidebar={setIsOpenSidebar}
              />
            );
        }
      }
    )}
    <li
      className="font-weight-bold d-none d-lg-block d-lg-inline-flex pb-4 pl-3rem pb-lg-0 m-0"
      key="btn-one"
    >
      <LinkButton
        textColor="c-white fs-0_875"
        round={4}
        className="d-none d-lg-block fs-0_75 py-1 px-1rem text-nowrap"
        url="https://app.instapay.pl/signup"
        backgroundColor="bgc-black"
        borderColor="bc-black"
        borderWidth="bw-2"
        wrapClassName="d-none d-lg-block"
      >
        Załóż konto
      </LinkButton>
    </li>
    <li
      className="font-weight-bold d-none d-lg-block d-lg-inline-flex pb-4 pl-1rem pb-lg-0 m-0"
      key="btn-two"
    >
      <LinkButton
        textColor="c-navy-blue fs-0_875"
        round={4}
        className="d-none d-lg-block fs-0_75 py-1 px-1rem"
        url="https://app.instapay.pl/login"
        backgroundColor="bgc-white"
        borderColor="bc-navy-blue"
        borderWidth="bw-2"
        wrapClassName="d-none d-lg-block"
      >
        Zaloguj
      </LinkButton>
    </li>
  </ul>
);
