import React, { useState } from "react";
import { shorten } from "../../utils/text";
import { getBorderColor, getColor } from "../../utils/classPicker";
import { useRef } from "react";

const rodoTxt =
  "Wyrażam zgodę na przetwarzanie przez Cashless Solutions sp. z o.o. moich danych osobowych w postaci imienia, nazwiska adresu poczty elektronicznej oraz numeru telefonu w celu przesyłania mi informacji marketingowych dotyczących produktów i usług oferowanych przez Cashless Solutions sp. z o.o. za pomocą środków komunikacji elektronicznej oraz za pomocą urządzeń telefonicznych, stosownie do treści przepisu art. 10 ust. 1 i 2 ustawy o świadczeniu usług drogą elektroniczną.";

export const RodoCheckbox = ({ name, value, touched, error, onChange }) => {
  const inputRef = useRef();
  const [checkboxClass, setCheckboxClass] = useState("");
  const [rodo, setRodo] = useState(`${shorten(rodoTxt, 15)}... `);

  const handleOnClick = (e) => {
    onChange(e);
    if (checkboxClass === "checked") {
      setCheckboxClass("");
      return;
    }

    setCheckboxClass("checked");
  };

  const handleReadMore = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.target.className = "d-none";
    setRodo(rodoTxt);
  };

  return (
    <>
      <label className="d-flex mt-3">
        <input
          ref={inputRef}
          name={name}
          type="checkbox"
          className="d-none"
          checked={value}
          onChange={handleOnClick}
        />
        <div className={`d-flex checkbox rounded border ${checkboxClass}`}>
          <div className={`tick ${getBorderColor(1)}`} />
        </div>
        <span
          className={`d-flex fs-0_875 mb-0 ml-3 cursor-pointer ${getColor(1)}`}
        >
          <span>
            {rodo}
            <span onClick={handleReadMore}>Więcej &raquo;</span>
          </span>
        </span>
      </label>
      {touched && error && <div className="c-red">{error}</div>}
    </>
  );
};
