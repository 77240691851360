import React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { RichText } from "../RichText";

export const MatrixItem = ({
  label,
  url,
  image,
  handleClick,
  text,
  columnNumber = 3,
}) => {
  const getColClass = () => {
    switch (columnNumber) {
      case 1:
        return "col-lg-12 min-w-lg-20rem";
      case 2:
        return "col-lg-6 min-w-lg-20rem";
      case 3:
        return "col-lg-4 min-w-lg-20rem";
      case 4:
        return "col-lg-3 min-w-lg-18rem";
    }
  };

  return (
    <AnchorLink
      className={`col-12 ${getColClass()} p-lg-4 navbar__matrixItem h-lg-shadow rounded-lg c-white c-lg-navy-blue text-decoration-none cursor-pointer text-right text-lg-left`}
      to={url}
      onAnchorLinkClick={handleClick}
    >
      <div className="d-inline-flex d-lg-flex align-items-center pb-4">
        {image && (
          <div
            className="d-none d-lg-block bgImage rounded-circle h-2rem w-2rem min-w-2rem"
            style={{ backgroundImage: `url(${image.fluid.src})` }}
          />
        )}
        <div className="fs-1 pl-4">{label}</div>
      </div>
      {text && (
        <RichText
          className="fs-0_75 d-none d-lg-block font-weight-normal list-disc list-pl-1_5rem"
          formatOptions={{ listItem: "raw" }}
          text={text.raw}
        />
      )}
    </AnchorLink>
  );
};
