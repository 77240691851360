import { useEffect, useState } from "react";

export const useScreenClick = () => {
  const [screen, setScreen] = useState({
    target: undefined,
  });

  useEffect(() => {
    const handleClick = (e) => {

      setScreen({
        target: e.target,
      });
    };

    document.addEventListener("click", (e) => handleClick(e));

    // Remove event listener on cleanup
    return () => document.removeEventListener("click", handleClick);
  }, []); // Empty array ensures that effect is only run on mount

  return screen;
};
