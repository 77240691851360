import ChevronUpIcon from "../Icon/ChevronUpIcon";
import ChevronDownIcon from "../Icon/ChevronDownIcon";
import React from "react";

export const Chevron = ({ dropdownIsVisible }) => {
  const className = "svg-inline fs-1_125 sw-2_5 pl-2";

  return dropdownIsVisible ? (
    <ChevronUpIcon className={className} />
  ) : (
    <ChevronDownIcon className={className} />
  );
};
