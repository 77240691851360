import React, { useState } from "react";

const Field = ({
  placeholder,
  name,
  onChange,
  value,
  touched,
  error,
  color = "c-white",
  colorActive = "c-cyan",
  borderColor = "bc-white",
  borderColorActive = "bc-cyan",
}) => {
  const [borderClass, setBorderClass] = useState(borderColor);
  const [inputClass, setInputClass] = useState(color);

  const handleOnFocus = () => {
    setBorderClass(borderColorActive);
    setInputClass(colorActive);
  };

  const handleOnBlur = () => {
    setBorderClass(borderColor);
    setInputClass(color);
  };

  return (
    <>
      <input
        name={name}
        value={value}
        placeholder={placeholder}
        className={`form-control my-3 p-5 d-inline-flex border-1 fs-1 w-100 w-lg-70 round-0rem bg-transparent ${inputClass} ${borderClass}`}
        onChange={onChange}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
      />

      {touched && error && <div className="c-red">{error}</div>}
    </>
  );
};

export default Field;
