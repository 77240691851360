import React from "react";

const ChevronUpIcon = ({ className }) => (
  <svg
    className={className}
    aria-hidden="true"
    focusable="false"
    role="img"
    aria-labelledby="chevron-up-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    id="chevron-up-svg"
  >
    <title id="chevron-up-icon">Chevron Up icon</title>
    <polyline points="18 15 12 9 6 15" />
  </svg>
);

export default ChevronUpIcon;
