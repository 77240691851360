import React from "react";

const MenuBurgerIcon = ({ isOpen, onClick, colorClass }) => {
  const color = colorClass || "bgc-blue bc-blue";

  return (
    <div className="d-flex d-lg-none" onClick={() => onClick(!isOpen)}>
      <div
        className={`b-menu cursor-pointer d-inline-block d-lg-none position-relative ${
          isOpen && "open"
        }`}
      >
        <div className={`b-bun b-bun--top ${color}`} />
        <div className={`b-bun b-bun--mid ${color}`} />
        <div className={`b-bun b-bun--bottom ${color}`} />
      </div>
    </div>
  );
};

export default MenuBurgerIcon;
