import { useEffect, useState } from "react";

export const useScroll = () => {
  const [scroll, setScroll] = useState({
    scrollY: undefined,
  });

  useEffect(() => {
    const handleScroll = () => {
      setScroll({
        scrollY: window.scrollY,
      });
    };

    window.addEventListener("scroll", handleScroll);

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleScroll);
  }, []); // Empty array ensures that effect is only run on mount

  return scroll;
};
