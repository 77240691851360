import React, { useRef, useState } from "react";
import { useOutsideAlerter } from "../../hooks/useOutsideAlerter";
import bookDemoImg from "../../images/icon/book-demo.png";
import { DropdownItem } from "./DropdownItem";
import { Chevron } from "./Chevron";
import { MatrixItem } from "./MatrixItem";

export const Matrix = ({ label, dropdownItems, columnNumber = 3 }) => {
  const [dropdownIsVisible, setDropdownIsVisible] = useState(false);
  const dropdownRef = useRef(null);
  const menuItemRef = useRef(null);

  useOutsideAlerter({
    ref: dropdownRef,
    isClicked: dropdownIsVisible,
    onClickOutside: () => setDropdownIsVisible(false),
    exceptRefs: [menuItemRef],
  });

  const handleClick = () => setDropdownIsVisible(!dropdownIsVisible);

  const getMatrixWrapClass = () => {
    const itemsNumber = dropdownItems.length;
    let matrixClass = "min-w-md-48rem";

    if (itemsNumber === 1) {
      return `${matrixClass} min-w-xxl-25rem`;
    }

    if (itemsNumber === 2) {
      return `${matrixClass} min-w-xxl-48rem`;
    }

    if (itemsNumber > 2) {
      return `${matrixClass} min-w-xxl-75rem`;
    }
  };

  return (
    <li className="font-weight-bold d-block d-lg-inline-flex pb-4 pb-lg-0 pl-0 pl-lg-3rem m-0 text-right fs-0_875">
      <span
        className="c-white c-lg-navy-blue cursor-pointer link text-nowrap"
        onClick={handleClick}
        ref={menuItemRef}
      >
        {label}
        <Chevron dropdownIsVisible={dropdownIsVisible} />
      </span>
      <div
        className={`justify-content-end justify-content-lg-start position-lg-absolute w-100 et-3 el-0 text-left br-4 shadow-sm navbar__Matrix ${
          dropdownIsVisible ? "d-flex" : "d-none"
        }`}
        ref={dropdownRef}
      >
        <div
          className={`px-lg-4rem py-lg-2rem align-items-stretch ${getMatrixWrapClass()}`}
        >
          <div className="row pb-lg-4 pt-4 pt-lg-0">
            {dropdownItems.map(({ id, url, label, image, text }) => (
              <MatrixItem
                key={id}
                url={url}
                label={label}
                text={text}
                image={image}
                handleClick={() => setDropdownIsVisible(false)}
                columnNumber={columnNumber}
              />
            ))}
          </div>
          {label === "Produkt" && (
            <div className="row d-none d-lg-block">
              <div className="col-12 border-top bw-1 bc-blue px-4 pt-6">
                <DropdownItem
                  key="demo"
                  url="https://calendly.com/instapaypl/demo"
                  label="Umów darmowe demo"
                  description="Poznaj wszystkie możliwości Instapay"
                  image={{ fluid: { src: bookDemoImg } }}
                  handleClick={() => setDropdownIsVisible(false)}
                  textClassName="min-w-lg-14rem"
                  wrapClass=""
                  dropdownItemClass=""
                  button={{
                    url: "https://calendly.com/instapaypl/demo",
                    outlineClass: "btn-outline-transparent",
                    backgroundColor: "bgc-black",
                    borderColor: "bc-black",
                    borderWidth: "bw-2",
                    textColor: "c-white",
                    className: "d-none d-lg-block fs-0_75 py-1 px-1rem",
                    text: "Wypróbuj",
                    round: 4,
                    wrapClassName: "d-none d-lg-block",
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </li>
  );
};
