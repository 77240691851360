import React, { useState } from "react";
import { Button } from "./Button";
import ArrowRight from "../images/arrow-right.png";
import { Loader } from "./Loader";
import Field from "./Field";
import { RodoCheckbox } from "./Form/RodoCheckbox";
import * as Yup from "yup";
import { Formik } from "formik";
import SelectTopic from "./SelectTopic";

const FormSchema = Yup.object().shape({
  topic: Yup.string()
    .min(1, "Temat wiadomości jest wymagany.")
    .required("Temat wiadomości jest wymagany."),
  name: Yup.string()
    .min(2, "Imię musi musi zawierać przynajmniej dwa znaki.")
    .required("Imię jest wymagane."),
  email: Yup.string()
    .email("Adres email ma nieprawidłowy format.")
    .required("Adres email jest wymagany."),
  phone: Yup.string().matches(/^[0-9+ ]*$/, "Telefon ma nieprawidłowy format."),
  consent: Yup.boolean()
    .oneOf([true], "Zgoda na przetwarzanie danych osobowych jest wymagana.")
    .required("Zgoda na przetwarzanie danych osobowych jest wymagana."),
});

export const ContactForm = ({ setMessage }) => {
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const resp = await fetch(
        "https://europe-west1-instapay-prod.cloudfunctions.net/apiv2/contact",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            withCredentials: true,
          },
          body: JSON.stringify(values),
        }
      );

      await resp.json();

      setMessage("Twoja wiadomość została wysłana.");

      setSubmitting(false);
    } catch (error) {
      console.error(error);
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        topic: "",
        name: "",
        phone: "",
        email: "",
        consent: false,
      }}
      validationSchema={FormSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        touched,
        values,
        isSubmitting,
        handleChange,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <SelectTopic touched={touched.topic} error={errors.topic} />
          <Field
            id="name"
            placeholder="Imię"
            name="name"
            value={values.name}
            touched={touched.name}
            error={errors.name}
            onChange={handleChange}
          />
          <Field
            id="phone"
            placeholder="Telefon"
            name="phone"
            value={values.phone}
            touched={touched.phone}
            error={errors.phone}
            onChange={handleChange}
          />
          <Field
            id="email"
            placeholder="Email"
            name="email"
            value={values.email}
            touched={touched.email}
            error={errors.email}
            onChange={handleChange}
          />
          <RodoCheckbox
            id="consent"
            name="consent"
            value={values.consent}
            touched={touched.consent}
            error={errors.consent}
            onChange={handleChange}
          />
          <div className="d-flex justify-content-center w-100 w-lg-70">
            <Button
              htmlType="submit"
              bgColor={10}
              borderColor={10}
              className="d-inline-flex justify-content-center align-items-center mt-3rem rounded-circle p-3"
            >
              {isSubmitting ? <Loader /> : <img src={ArrowRight} alt="" />}
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};
