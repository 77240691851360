import { AnchorLink } from "gatsby-plugin-anchor-links";
import React from "react";

export const PlainLink = ({ label, url, setIsOpenSidebar }) => {
  return (
    <li className="font-weight-bold d-block d-lg-inline-flex pb-4 pb-lg-0 pl-0 pl-lg-3rem m-0 text-right fs-0_875">
      <AnchorLink
        className="c-white c-lg-navy-blue text-nowrap"
        to={url}
        onAnchorLinkClick={() => setIsOpenSidebar(false)}
      >
        {label}
      </AnchorLink>
    </li>
  );
};
