import React, { useEffect, useState } from "react";
import { Section } from "./Section";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { getPathname } from "../utils/location";
import logoImg from "../images/instapay-logo-white.png";
import { ContactEmail, Copyrights, SocialItems } from "./Common";
import { useWindowSize } from "../hooks/useWindowSize";

export const Footer = ({
  copyrights,
  contactEmail,
  navigation,
  social,
  termsAndConditions,
}) => {
  const windowSize = useWindowSize();
  const [sectionRef, setSectionRef] = useState(null);
  const [sectionClass, setSectionClass] = useState("");

  useEffect(() => {
    if (!sectionRef) {
      return;
    }

    const rect = sectionRef.getBoundingClientRect();

    if (rect.y < windowSize.height && window.scrollY < windowSize.height) {
      setSectionClass("position-fixed w-100 eb-0");
    } else {
      setSectionClass("");
    }
  }, [windowSize.width, sectionRef]);

  return (
    <div ref={(el) => setSectionRef(el)}>
      <Section
        bgColor={10}
        className={`pt-3rem pb-1rem py-lg-5rem ${sectionClass}`}
      >
        <div className="col-12 col-lg-8 pb-2rem text-center text-lg-left">
          <img
            src={logoImg}
            alt="Instapay Logo"
            className="w-7rem w-lg-14rem pb-0 pb-lg-6"
          />
          <Copyrights copyrights={copyrights} className="d-none d-lg-block" />
        </div>
        <div className="col-12 col-lg-4">
          {navigation && (
            <MenuItems
              items={navigation}
              termsAndConditions={termsAndConditions}
            />
          )}
          {social && <SocialItems items={social} />}
          <Copyrights
            copyrights={copyrights}
            className="d-block d-lg-none text-center pb-6"
          />
          <ContactEmail contactEmail={contactEmail} />
        </div>
      </Section>
    </div>
  );
};

const MenuItems = ({ items, termsAndConditions }) => (
  <ul className="c-white d-flex flex-wrap justify-content-center justify-content-lg-end pl-0">
    {items.map(({ id, label, url }) => {
      if (url.startsWith("#")) {
        url = `${getPathname()}${url}`;
      }

      return (
        <li
          className="font-weight-bold d-block d-lg-inline-flex pb-4 px-4 pl-lg-3rem pr-lg-0 m-0"
          key={id}
        >
          <AnchorLink to={url} className="c-white">
            {label}
          </AnchorLink>
        </li>
      );
    })}
  </ul>
);
