import { AnchorLink } from "gatsby-plugin-anchor-links";
import { LinkButton } from "../LinkButton";
import React from "react";

export const DropdownItem = ({
  label,
  url,
  description,
  image,
  handleClick,
  button,
  textClassName = "",
  linkClassName = "",
  wrapClass = "p-2",
  dropdownItemClass = "p-2 navbar__dropdownItem",
}) => {
  return (
    <AnchorLink
      className="c-white c-lg-navy-blue w-100"
      to={url}
      onAnchorLinkClick={handleClick}
    >
      <div className={wrapClass}>
        <div className={`d-flex align-items-center ${dropdownItemClass}`}>
          {image && (
            <div
              className="bgImage rounded-circle h-2rem w-2rem min-w-2rem"
              style={{ backgroundImage: `url(${image.fluid.src})` }}
            />
          )}
          <div className={`pl-1rem ${textClassName}`}>
            <div className="fs-0_75">{label}</div>
            <div className="fs-0_75 font-weight-normal">{description}</div>
          </div>
          {button && (
            <div
              className={`pl-2rem align-items-center d-flex ${
                button.wrapClassName || ""
              }`}
            >
              <LinkButton {...button} />
            </div>
          )}
        </div>
      </div>
    </AnchorLink>
  );
};
