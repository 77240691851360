import React, { useEffect, useState } from "react";
import { useFormikContext } from "formik";

const items = [
  { label: "Temat wiadomości", value: "" },
  {
    label: "Chcę założyć konto dla firmy",
    value: "create_account_for_company",
  },
  {
    label: "Chcę założyć konto dla płatnika",
    value: "create_account_for_customer",
  },
  {
    label: "Mam pytanie dot. współpracy biznesowej",
    value: "cooperation",
  },
  {
    label: "Mam pytanie dot. mojej płatności dla usługodawcy",
    value: "service_provider_payment",
  },
  {
    label: "Reklamacja i zwroty",
    value: "complaints_and_returns",
  },
  {
    label: "Inne",
    value: "another",
  },
];

const SelectTopic = ({ toggleClass = "c-white bc-white", touched, error }) => {
  const [isOpened, setIsOpened] = useState(false);
  const [selected, setSelected] = useState(items[0]);
  const formikProps = useFormikContext();

  useEffect(() => formikProps.setFieldValue("topic", selected.value), [
    selected,
  ]);

  const handleOnInputClick = () => setIsOpened(!isOpened);

  const handleOnItemClick = async (e) => {
    const value = items.filter(
      (item) => item.value === e.target.getAttribute("data-value")
    );

    setSelected(value[0]);
    setIsOpened(false);
  };

  return (
    <div>
      <input
        value={selected.label}
        className={`form-control my-3 p-5 d-inline-flex border-1 fs-1 w-100 w-lg-70 round-0rem bg-transparent cursor-pointer ${toggleClass}`}
        onClick={handleOnInputClick}
        readOnly
      />
      {touched && error && <div className="c-red">{error}</div>}
      <ul
        className={`list-unstyled border round-0rem bw-1 bc-white w-lg-70 pr-1rem pr-md-0  ${
          isOpened ? "d-block" : "d-none"
        }`}
      >
        {items.map(
          ({ label, value }, index) =>
            value !== "" && (
              <li
                className={`d-inline-block d-md-block pb-1rem c-white cursor-pointer px-5 py-2 bw-1 bc-white fs-0_875 ${
                  index > 1 ? "border-top" : ""
                }`}
                data-value={value}
                onClick={handleOnItemClick}
                key={index}
              >
                {label}
              </li>
            )
        )}
      </ul>
    </div>
  );
};

export default SelectTopic;
