import React from "react";
import { Section } from "./Section";
import { RichText } from "./RichText";

export const Identifications = ({
  identifications,
  backgroundColor,
  textColor,
  spaceBottom,
  spaceTop,
  description,
}) => {
  const colNumber = identifications.length;
  const colClass = `col-${12 / Math.round(colNumber / 2)} col-lg-${
    12 / colNumber
  }`;

  return (
    <Section
      className={`pb-2rem pb-lg-${spaceBottom || 5}rem pt-${
        spaceTop || 5
      }rem ${backgroundColor}`}
    >
      {identifications.map(({ image }, index) => (
        <div
          className={`${colClass} text-center position-relative pb-4rem`}
          key={index}
        >
          {image && (
            <div
              className="h-md-4rem w-100 d-flex justify-content-center align-items-center"
              style={{ height: "55px" }}
            >
              <img src={image.fluid.src} alt="icon" className="max-w-100" />
            </div>
          )}
        </div>
      ))}
      {description && (
        <div className="col-12 text-center text-lg-left">
          <RichText className={`${textColor}`} text={description.raw} />
        </div>
      )}
    </Section>
  );
};
