import React, { useRef, useState } from "react";
import { useOutsideAlerter } from "../../hooks/useOutsideAlerter";
import bookDemoImg from "../../images/icon/book-demo.png";
import { DropdownItem } from "./DropdownItem";
import { Chevron } from "./Chevron";

export const Dropdown = ({ label, dropdownItems }) => {
  const [dropdownIsVisible, setDropdownIsVisible] = useState(false);
  const dropdownRef = useRef(null);
  const menuItemRef = useRef(null);

  useOutsideAlerter({
    ref: dropdownRef,
    isClicked: dropdownIsVisible,
    onClickOutside: () => setDropdownIsVisible(false),
    exceptRefs: [menuItemRef],
  });

  const handleClick = () => setDropdownIsVisible(!dropdownIsVisible);

  return (
    <li className="font-weight-bold d-block d-lg-inline-flex pb-4 pb-lg-0 pl-0 pl-lg-3rem m-0 text-right fs-0_875 position-relative">
      <span
        className="c-white c-lg-navy-blue cursor-pointer link"
        onClick={handleClick}
        ref={menuItemRef}
      >
        {label}
        <Chevron dropdownIsVisible={dropdownIsVisible} />
      </span>
      <div
        className={`justify-content-end justify-content-lg-start position-lg-absolute min-w-lg-20rem et-2 el-3 text-left br-4 shadow-sm navbar__Dropdown ${
          dropdownIsVisible ? "d-flex" : "d-none"
        }`}
        ref={dropdownRef}
      >
        <div className="d-inline-block d-lg-flex flex-lg-wrap">
          {dropdownItems.map(({ id, url, label, image, description }) => (
            <DropdownItem
              key={id}
              url={url}
              label={label}
              description={description}
              image={image}
              handleClick={() => setDropdownIsVisible(false)}
            />
          ))}
          {label === "Produkt" && (
            <DropdownItem
              key="demo"
              url="https://calendly.com/instapaypl/demo"
              label="Umów darmowe demo"
              description="Poznaj wszystkie możliwości Instapay"
              image={{ fluid: { src: bookDemoImg } }}
              handleClick={() => setDropdownIsVisible(false)}
              textClassName="min-w-lg-14rem"
              button={{
                url: "https://calendly.com/instapaypl/demo",
                outlineClass: "btn-outline-transparent",
                backgroundColor: "bgc-black",
                borderColor: "bc-black",
                borderWidth: "bw-2",
                textColor: "c-white",
                className: "d-none d-lg-block fs-0_75 py-1 px-1rem",
                text: "Wypróbuj",
                round: 4,
                wrapClassName: "d-none d-lg-block",
              }}
            />
          )}
        </div>
      </div>
    </li>
  );
};
